import fetchPayload from "@local/payload-client/src/fetchPayload"
import { STORE_ID } from "./const"
import type { Payload } from "@local/payload-client/src/types"

export const getMini = async () => {
  const res = await fetchPayload<Payload.Mini>([
    `/mini/${STORE_ID}/`,
    "en",
    "?depth=2",
    {
      tags: ["channel"],
    },
  ])

  if (!res) {
    throw new Error("Failed to fetch data")
  }

  return res
}
