import { getMini } from "@/lib/getMini";
import MailerSignupClient from "./Mailer";
import type { Payload } from "@local/payload-client/src/types";
interface Props {
  block: Payload.MailerSignupBlock;
}
export const MailerSignupServer = async ({
  block
}: Props) => {
  const initialData = await getMini();
  return <MailerSignupClient storeName={initialData.name} block={block} data-sentry-element="MailerSignupClient" data-sentry-component="MailerSignupServer" data-sentry-source-file="index.tsx" />;
};
export default MailerSignupServer;